import Container from '../DI/Container';
import { i18nInstanceSymbol } from '../DI/Providers/i18nInstanceSymbol';
import { initTranslations } from './initTranslations';
import { IConfig, NamespaceResourcesMap } from './types';

export interface II18nServiceProps<Lang extends string = string> {
  container: Container,
  config: IConfig,
  locale: Lang,
  namespaces?: string[],
  resources?: NamespaceResourcesMap<Lang>,
}

const I18nService = ({ container, config, locale, namespaces, resources }: II18nServiceProps) => {
  const i18nInstance = initTranslations({ config, locale, namespaces, resources });
  container.bindValue(i18nInstanceSymbol, i18nInstance);

  return null;
};

export default I18nService;
