import '@/Framework/browser/polyfill';
import { AppProps } from 'next/app';
import { Store } from 'redux';
import { useRouter } from 'next/router';
import Head from 'next/head';
import GlobalStyles, { styles } from '@/dealroadshow/ui/common/GlobalStyles';
import Container from '@/Framework/DI/Container';
import Bootstrap, { healthCheck, wrapper } from '@/Framework/Bootstrap';
import reducer from '@/dealroadshow/reducer';
import ErrorBoundary from '@/Framework/ErrorHandling/ErrorBoundary';
import ApplicationFallback from '@/Framework/ErrorHandling/ErrorBoundary/ApplicationFallback';
import ContentWrp from '@/Framework/UI/Templates/ContentWrp';
import { Provider } from 'react-redux';
import GlobalProps from '@/Framework/Router/Next/GlobalProps';
import TimeZoneContextProvider from '@/Framework/TimeZone/TimeZoneContext';
import { IGlobalProps } from '@/Framework/Router/Next/withGlobalProps';
import I18nService from '@/Framework/i18n/I18nService';
import config from '@/dealroadshow/application/config/config';

const App = ({ Component, pageProps, container, store }: AppProps<IGlobalProps> & {
  container: Container,
  store: Store,
}) => (
  <GlobalStyles>
    <Head>
      <title>Deal Roadshow</title>
      <meta charSet="utf-8" />
      <meta name="referrer" content="no-referrer-when-downgrade" />
      <meta
        httpEquiv="Cache-Control"
        content="no-store, no-cache, must-revalidate, proxy-revalidate, max-age=0"
      />
      <meta httpEquiv="Pragma" content="no-store" />
      <meta httpEquiv="Expires" content="0" />
      <meta
        content="initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no"
        name="viewport"
      />
      <meta name="HandheldFriendly" content="true" />
    </Head>
    { /* @ts-ignore */ }
    <ErrorBoundary fallback={ ApplicationFallback }>
      <Bootstrap
        container={ container }
        session={ pageProps?.session }
        store={ store }
        cookies={ pageProps?.cookies }
      >
        <I18nService
          container={ container }
          config={ config.i18n }
          locale={ useRouter().locale }
        />
        <GlobalProps { ...pageProps }>
          <TimeZoneContextProvider>
            <ContentWrp className={ styles.body }>
              <Provider store={ store }>
                <Component { ...pageProps } />
              </Provider>
            </ContentWrp>
          </TimeZoneContextProvider>
        </GlobalProps>
      </Bootstrap>
    </ErrorBoundary>
  </GlobalStyles>
);

export default healthCheck(wrapper(reducer).withRedux(App));
