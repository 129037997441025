import { createInstance } from 'i18next';
import { initReactI18next } from 'react-i18next/initReactI18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ICU from 'i18next-icu';
import { IConfig, NamespaceResourcesMap } from './types';

interface IInitTranslation<Lang extends string> {
  config: IConfig,
  locale: string,
  namespaces?: string[],
  resources?: NamespaceResourcesMap<Lang>,
}

export function initTranslations<Lang extends string>({
  config,
  locale,
  namespaces = [],
  resources,
}: IInitTranslation<Lang>) {
  const i18nInstance = createInstance();
  i18nInstance.use(initReactI18next);

  const namespacesFromResources = Object.keys(resources?.[locale] || {});

  const mergedNamespaces = [
    ...namespaces,
    ...namespacesFromResources,
  ];

  const i18nextOptions = {
    resources,
    fallbackLng: config.defaultLocale,
    supportedLngs: config.locales,
    defaultNS: mergedNamespaces?.[0],
    fallbackNS: mergedNamespaces?.[0],
    ns: mergedNamespaces ? [...mergedNamespaces] : [],
    preload: resources ? [] : config.locales,
    interpolation: {
      escapeValue: false,
    },
      localeDetectionPaths: ['^/$'],
    react: {
      useSuspense: false,
      bindI18nStore: 'added',
    },
    detection: config.detection,
  };

  i18nInstance
    .use(LanguageDetector)
    .use(ICU)
    .init(i18nextOptions);
  // *** call without param to use language detector
  i18nInstance.changeLanguage(locale);

  return i18nInstance;
}
